import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { styled } from "@material-ui/core";

type Props = {
  title: string;
  subtext: string;
  severity?: "error" | "warning" | "info" | "success";
};

const StyledAlert = styled(Alert)({
  padding: "0.5rem 2rem",
  alignItems: "center",

  ["@media (max-width: 960px)"]: {
    padding: "0.5rem 1rem",
  },

  "& .maintenance-title": {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "0",
  },
});

export const MaintenanceBanner = ({ title, subtext, severity }: Props) => {
  return (
    <StyledAlert severity={severity || "warning"}>
      <AlertTitle className="maintenance-title">{title}</AlertTitle>
      <span>{subtext}</span>
    </StyledAlert>
  );
};
