import {
  IPerson,
  CorpRoomProduct,
  CorpRoomInfoProducts,
  CorpLodging,
  HotelPriceQuoteScheduleRequestV2,
  RoomProduct,
  PriceQuote,
  Lodging,
  RoomInfoProducts,
  CorpRoomInfo,
  CorpLodgingData,
} from "redmond";
import { PaymentOpaqueValue } from "@b2bportal/purchase-api";
import dayjs from "dayjs";

export const formatDate = (date: any) => {
  return dayjs(date).format("YYYY-M-D");
};

interface getCorpHotelBookStateProps {
  chosenProduct: CorpRoomProduct | RoomProduct | null;
  guests: IPerson[];
  isApprovalRequired: boolean;
  roomInfoProduct: CorpRoomInfoProducts | RoomInfoProducts | null;
  priceQuote: PriceQuote | null;
  selectedLodging: Lodging | CorpLodging | null;
  checkIn: Date | null;
  checkOut: Date | null;
  contactEmail: string | undefined;
  priceQuoteRequest: HotelPriceQuoteScheduleRequestV2;
  opaquePayments: PaymentOpaqueValue[] | null;
  approvalRequestReason: string | undefined;
  requestedAt: string;
  loyaltyNumber?: string;
}

export const getHotelApprovalRequest = ({
  chosenProduct,
  guests,
  isApprovalRequired,
  roomInfoProduct,
  approvalRequestReason,
  priceQuote,
  selectedLodging,
  checkIn,
  checkOut,
  contactEmail,
  priceQuoteRequest,
  opaquePayments,
  requestedAt,
  loyaltyNumber,
}: getCorpHotelBookStateProps) => {
  const corporateTravel = (roomInfoProduct?.roomInfo as CorpRoomInfo)
    .corporateTravel;

  const submitForApprovalRequest = {
    approvalReason: approvalRequestReason || "",
    cancellationPolicy: chosenProduct?.cancellationPolicy,
    checkInDate: formatDate(checkIn) || "",
    checkOutDate: formatDate(checkOut) || "",
    contactEmail: contactEmail || "",
    guests,
    lodgingData: selectedLodging?.lodging as CorpLodgingData,
    paymentInfo: opaquePayments?.[0]?.value || [],
    policyCompliance: corporateTravel?.policyCompliance,
    pricing: priceQuote?.pricing,
    roomInfo: roomInfoProduct?.roomInfo as CorpRoomInfo,
    shopRequest: chosenProduct?.opaqueQuoteRequest || "",
    state: { ReservationState: "Pending" },
    totalPrice: chosenProduct?.tripTotal.fiat,
    trackingProperties: chosenProduct?.trackingPropertiesV2,
    hotelQuoteRequest: priceQuoteRequest,
    requestedAt,
    isApprovalRequired,
    loyaltyNumber,
  };

  return submitForApprovalRequest;
};
