import React, { createContext, FC, PropsWithChildren } from "react";

type ExperimentsHookContext = {
  isLoggedIn: boolean;
};

export const ExperimentsHookContext = createContext<
  ExperimentsHookContext | undefined
>(undefined);

export const ExperimentsHookProvider: FC<
  PropsWithChildren<{
    isLoggedIn: boolean;
  }>
> = ({ isLoggedIn, children }) => {
  return (
    <ExperimentsHookContext.Provider value={{ isLoggedIn }}>
      {children}
    </ExperimentsHookContext.Provider>
  );
};
