export const CORP_TRAVEL_CREDITS_BODY = `<ol>
<li><strong>Travel Credits.</strong> Capital One Travel for Business may give you credits in connection with the terms and conditions of certain free or purchased products (e.g., Price Match Guarantee) available for use on the Capital One Travel for Business website and mobile application. If you have separate access to the standard Capital One Travel website, you may access these credits there as well. For purposes of this “Travel Credits” subsection , all mentions of Capital One Travel for Business also include Capital One Travel, for customers that have access to Capital One Travel. These travel credits may accumulate in your Capital One Travel for Business account, along with other travel credits received from other qualified bookings or purchases. Your accumulated balance of travel credits may only be used for future qualified purchases on the Capital One Travel for Business website and application. Travel credits are not actual cash/currency, stored value, or account credit, and have no relation to Capital One Credit Card rewards discussed elsewhere in these terms and conditions. Travel credits are an incentive provided to you for loyalty, award or promotional purposes only and may only be used toward qualifying purchases by you via the Capital One Travel for Business website and mobile application. Available travel credits will be displayed in the “My Travel Credits and Offers” page, accessible by clicking on the “My Travel Credits and Offers” icon in the navigation bar. Your receipt and use of any travel credits serves as your agreement to the terms and conditions set forth herein and any any rules and restrictions communicated to you at the time of the offer, issuance, award, grant, and/or receipt of travel credits, and/or as displayed in your Capital One Travel for Business account in connection with each of the travel credits granted to you (“Rules”).</li> 
<br>
<li><strong>Restrictions on Travel Credits.</strong> All travel credits are non-transferrable. All travel credits expire as indicated at issuance, at the cardholder's death, or when the cardholder no longer has an eligible credit card account for Capital One Travel for Business, whichever occurs first. The Rules applicable to travel credits may include: restrictions on the nature of the products, services, and fees to which travel credits may be applied; restrictions on the eligibility of users to receive and use travel credits, and restrictions on availability of Capital One Travel for Business offers. Capital One Travel for Business reserves the right to make changes to these travel credits terms and conditions and/or any applicable travel credit at any time, as determined solely in Capital One Travel for Business’s business discretion.</li>
<br>
<li><strong>No Cash Value.</strong> Travel credits may not be redeemed or exchanged for cash and have no cash value. You have no property interest in travel credits. Capital One Travel for Business may choose to discontinue a published, offered, or granted travel credit or any other incentive offer or promotional program at any time solely in Capital One’s business discretion. If your use of the Capital One Travel for Business websites or mobile application is suspended or terminated by Capital One Travel for Business for any reason, all travel credits will expire immediately. Capital One Travel for Business is not obligated to compensate you for any value associated with expired or discontinued incentives, promotions, or travel credits.</li>
<br>
<li><strong>Redemption.</strong> At the time of a qualified transaction using the Capital One website or mobile application, you will have the option to redeem travel credits applicable to your purchase. If the amount of travel credit redeemed/applied is less than the total transaction amount, you authorize Capital One Travel for Business to charge your linked Capital One Credit Card or redeem rewards, as indicated to you at the time of purchase, for the remaining transaction amount.</li>
<br>
<li><strong>No Warranties.</strong> Capital One Travel for Business disclaims all express or implied warranties, including warranties of merchantability or fitness for a particular purpose, as to travel credits or any offers associated with travel credits, to the fullest extent permitted by law.</li>
<br>
<li><strong>Corrections and Disputes.</strong> Capital One Travel for Business reserves the right to adjust the balance of any travel credits in the event of a clerical, billing, or accounting error as determined solely in Capital One Travel for Business’s business discretion. Transactions or corrections concerning travel credits may be disputed by contacting Capital One Travel for Business Support within 60 days of the disputed transaction or correction.</li>
<br>
<li><strong>Unauthorized Use of Travel Credits.</strong> Capital One Travel for Business is not responsible for any unauthorized redemption of travel credits associated with your Capital One Travel for Business user account. You are solely responsible for loss or unauthorized use of any device from which your Capital One account may be accessible. To help secure your travel credits, do not share access to your Capital One account with others. If you suspect that someone has accessed your account and/or used your travel credits without authorization, contact Capital One Travel for Business Support immediately.</li>
</ol>`;
