import { Tenant } from "redmond";

const CORPORATE_PORTAL_URL = window.__mclean_env__?.CORPORATE_PORTAL_URL || "";
export const isValidBusinessTravelURL = (url: string) =>
  url.startsWith(CORPORATE_PORTAL_URL);

export const isCorpTenant = (tenant: Tenant | undefined) =>
  tenant === "capone-corporate";

export const isCaponeTenant = (tenant: Tenant | undefined) =>
  tenant === "capone";

const tenants = ["capone", "capone-corporate"] satisfies Tenant[];

// this function ensures tenant in config will always fall back to 'capone' if leamouth config is outdated
export const getConfigTenant = () => {
  const configTenant = (window.__mclean_env__?.TENANT ??
    window.__portal_env__?.TENANT ??
    process.env?.TENANT ??
    "capone") as Tenant;

  if (tenants.includes(configTenant)) {
    return configTenant;
  } else {
    return "capone";
  }
};

export const getTenantName = (tenant: Tenant) => {
  const isCorporate = isCorpTenant(tenant);
  return `Capital One Travel${isCorporate ? " for Business" : ""}`;
};
