import {
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalAlertChoiceProperties,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
} from "redmond";

export const getShowOOPModalEvent = (
  screen: ModalScreens,
  funnel: ModalAlertProperties["funnel"],
  modalType: "out_of_policy_auto" | "out_of_policy"
) => ({
  eventName: MODAL_ALERT,
  properties: {
    type: modalType,
    screen: screen,
    category: ModalCategoryType.FEATURE,
    primary_button: "change selection",
    secondary_button: "continue anyway",
    modal_title: "This rate is out of policy",
    modal_subtitle: "",
    agent_title: "This rate is out of policy",
    agent_subtitle: "",
    funnel: funnel,
    step: "",
  } satisfies ModalAlertProperties,
});

export const getClickContinueOOPModalEvent = (
  screen: ModalScreens,
  funnel: ModalAlertProperties["funnel"],
  modalType: "out_of_policy_auto" | "out_of_policy"
) => ({
  eventName: MODAL_ALERT_CHOICE,
  properties: {
    type: modalType,
    screen: screen,
    category: ModalCategoryType.FEATURE,
    primary_button: "change selection",
    secondary_button: "continue anyway",
    modal_title: "This rate is out of policy",
    modal_subtitle: "",
    agent_title: "This rate is out of policy",
    agent_subtitle: "",
    funnel: funnel,
    step: "",
    button_choice: ModalButtonType.SECONDARY,
  } satisfies ModalAlertChoiceProperties,
});

export const getClickCancelOOPModalEvent = (
  screen: ModalScreens,
  funnel: ModalAlertProperties["funnel"],
  modalType: "out_of_policy_auto" | "out_of_policy"
) => ({
  eventName: MODAL_ALERT_CHOICE,
  properties: {
    type: modalType,
    screen: screen,
    category: ModalCategoryType.FEATURE,
    primary_button: "change selection",
    secondary_button: "continue anyway",
    modal_title: "This rate is out of policy",
    modal_subtitle: "",
    agent_title: "This rate is out of policy",
    agent_subtitle: "",
    funnel: funnel,
    step: "",
    button_choice: ModalButtonType.PRIMARY,
  } satisfies ModalAlertChoiceProperties,
});
